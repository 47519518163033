import React, { useState, useEffect } from 'react';
import * as FormValidator from '../../validation/index.js';
import Referrer from './referrer.js';
import Patient from './patient.js';
import Risk from './risk.js';
import Demographics from './demographics.js';
import ReferralReason from './referralReason.js';
import ErrorNotification from '../base/errorNotification.js';
import LoadingSpinner from '../base/loadingSpinner.js';
import { pdfReferenceValues } from '../../utils/pdfReferenceValues.js';
import { Row, Col, Alert, Card, Button } from 'react-bootstrap';
import {
  getAllEducationPlansInPlaceReference,
  getAllGPPractice,
  getAllReferrerTitle,
  getAllNdtContactReference,
  getAllGenLanguages,
  getAllSchools,
  getAllGPRegistration,
  getAllCategoryReference,
  getAllMannerismsReference,
  saveReferral,
  getAllBhamPostCodes,
  pdfNeuroReferral,
  getAllReferralTypeReference,
  getAllEthnicityReference,
} from '../../utils/sendRequest.js';

import Wizard from './wizardForm.js';
import moment from 'moment';
// import { BigTest } from '../../utils/sendRequest.js';

const WizardStep = ({ children }) => children;

export default function NeuroForm() {
  const [eppRef, setEppRef] = useState();
  const [gPPractice, setGPPractice] = useState();
  const [school, setSchool] = useState();
  const [gPRegistration, setGPRegistration] = useState();
  const [referrerTitle, setReferrerTitle] = useState();
  const [ndtContactRef, setNdtContactRef] = useState();
  const [genLanguages, setGenLanguages] = useState();
  const [mannerismsRef, setMannerismsRef] = useState();
  const [pdfFormValues, setPdfFormValues] = useState();
  const [categoryRef, setCategoryRef] = useState();
  const [referralTypeRef, setReferralTypeRef] = useState();
  const [ethnicityRef, setEthnicityRef] = useState();

  const [serverError, setServerError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [success, setSuccess] = useState(false);
  const [failed, setFailed] = useState();

  const [patientName, setPatientName] = useState('');

  const pdfDownload = async () => {
    const pdf = pdfReferenceValues(
      pdfFormValues,
      referrerTitle,
      ndtContactRef,
      genLanguages,
      school,
      gPPractice,
      eppRef,
      gPRegistration,
      categoryRef,
      mannerismsRef,
      referralTypeRef,
      ethnicityRef,
    );

    try {
      const resPdf = await pdfNeuroReferral(pdf);

      setProcessing(true);

      const file = new Blob([resPdf], { type: 'application/pdf' });
      //IE fix
      // window navigator - detect browser
      //msSaveOrOpenBlob ie method to save a file in a browser
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(file);
        return;
      }

      //Build a URL from the file
      const fileURL = URL.createObjectURL(file);
      const link = document.createElement('a');
      link.href = fileURL;
      link.download = `Neuro-Referral ${pdfFormValues.inputDate}.pdf`;
      link.click();

      setTimeout(() => {
        URL.revokeObjectURL(link);
        link.remove();
        setProcessing(false);
        setFailed(null);
      }, 2000);
    } catch (error) {
      setProcessing(false);
      setFailed(error);
    }
  };

  const checkBhamPostCodes = async (postCode) => {
    setProcessing(true);

    try {
      const res = await getAllBhamPostCodes({ postCode: postCode });
      setProcessing(false);
      setFailed(null);
      return res;
    } catch (error) {
      setProcessing(false);
      setFailed(error);
    }
  };

  useEffect(() => {
    async function getFormOptions() {
      try {
        const [
          referrerTitle,
          ndtContactRef,
          genLanguages,
          schools,
          gpPractices,
          eppRef,
          gPRegistration,
          categoryRef,
          mannerismsRef,
          referralTypeRef,
          ethnicityRef,
        ] = await Promise.all([
          getAllReferrerTitle(),
          getAllNdtContactReference(),
          getAllGenLanguages(),
          getAllSchools(),
          getAllGPPractice(),
          getAllEducationPlansInPlaceReference(),
          getAllGPRegistration(),
          getAllCategoryReference(),
          getAllMannerismsReference(),
          getAllReferralTypeReference(),
          getAllEthnicityReference(),
        ]);
        setReferrerTitle(referrerTitle);
        setNdtContactRef(ndtContactRef);
        setGenLanguages(genLanguages);
        setGPPractice(gpPractices);
        setGPRegistration(gPRegistration);
        setSchool(schools);
        setEppRef(eppRef);
        setCategoryRef(categoryRef);
        setMannerismsRef(mannerismsRef);
        setReferralTypeRef(referralTypeRef);
        setEthnicityRef(ethnicityRef);
        setLoading(false);
      } catch (err) {
        setServerError(err);
        setReferrerTitle(null);
        setNdtContactRef(null);
        setGenLanguages(null);
        setGPPractice(null);
        setGPRegistration(null);
        setSchool(null);
        setEppRef(null);
        setCategoryRef(null);
        setMannerismsRef(null);
        setReferralTypeRef(null);
        setEthnicityRef(null);
        setLoading(false);
      }
    }
    getFormOptions();
  }, []);

  if (loading) {
    return (
      <Row>
        <Col xs={12} sm={12} md={3}></Col>
        <Col xs={12} sm={12} md={9}>
          <LoadingSpinner />
        </Col>
      </Row>
    );
  } else {
    if (serverError) {
      return <ErrorNotification alertMessage={serverError.message} />;
    } else {
      if (success) {
        return (
          <Row>
            <Col xs={12} sm={12} md={12}>
              <>
                {failed && (
                  <Alert variant="danger">Error downloading Neuro PDF</Alert>
                )}
                <Alert variant="success">
                  <Alert.Heading>{`${patientName} - Neurodevelopment form successfuly submitted`}</Alert.Heading>
                  <p>
                    This referral has been submitted for triage by the
                    Neurodevelopmental Pathway clinical team. You will be
                    notified by email to confirm whether the referral has been
                    accepted for screening and assessment. If the child is
                    accepted on to the Neurodevelopmental Pathway then the
                    child’s educational setting will be contacted for further
                    information.
                  </p>
                  <hr />
                  <p>
                    Unfortunately there are waiting times for these assessments
                    but we are working hard to reduce them, thank you for your
                    cooperation.
                  </p>
                  <hr />
                  <p className="mb-0">
                    Please refresh the page to submit another referral.
                  </p>
                  <Button
                    className="mt-3"
                    onClick={pdfDownload}
                    disabled={processing}
                  >
                    Download Referral PDF
                  </Button>
                </Alert>
              </>
            </Col>
          </Row>
        );
      } else {
        return (
          <>
            {failed && (
              <Row>
                <Col xs={12} sm={12} md={3}></Col>
                <Col xs={12} sm={12} md={9}>
                  <Card bg="danger" text="light" className="mb-3">
                    <Card.Header>Error</Card.Header>
                    <Card.Body>
                      {`There is a problem - error completing request. ${failed.message}.`}
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            )}
            <Wizard
              initialValues={{
                //1
                childFirstName: '',
                childSurname: '',
                childDOB: '',
                referralTypeID: '',
                childResidingID: '',
                ethnicityID: '',
                earlyHelp: '',
                socialWorkerName: '',
                socialWorkerTel: '',
                socialWorkerEmail: '',
                socialWorkerPlacement: '',
                childAddressLine1: '',
                childAddressLine2: '',
                childAddressLine3: '',
                childAddressLine4: '',
                childAddressPostcode: '',
                gPRegistrationID: '',
                gPPracticeID: '',

                //2
                severeLearningDisability: 'No',
                referrerConsent: 'Yes',
                referrerConsentDate: '',
                parentalResponibilityID: '',
                referrerName: '',
                referrerTitleID: '',
                referrerEmail: '',
                referrerTelephone: '',
                referrerMobile: '',
                referrerAddressLine1: '',
                referrerAddressLine2: '',
                referrerAddressLine3: '',
                referrerAddressLine4: '',
                referrerAddressPostcode: '',
                referrerNdtContactConsent: 'Yes',
                referrerNdtContact: [],
                referrerNdtContactOther: '',

                //3
                nhsNumber: '',
                parentName: '',
                parentEmail: '',
                parentTelephone: '',
                parentAddressDiffChild: 'No',
                parentAddressLine1: '',
                parentAddressLine2: '',
                parentAddressLine3: '',
                parentAddressLine4: '',
                parentAddressPostcode: '',
                interpreter: 'No',
                interpreterGenLanguageCode: '',
                inSchool: 'Yes',
                homeEducation: 'No',
                schoolID: '',
                schoolNameOther: '',
                schoolNurseryAddress: '',
                schoolNurseryContactPerson: '',
                schoolNurseryContactPersonTel: '',
                schoolEmail: '',
                schoolTelephone: '',

                //4
                childTheirSafetyID: '',
                childTheirSafetyComment: '',
                childSafetyOfOthersID: '',
                childSafetyOfOthersComment: '',
                childExclusionID: '',
                childAdoptionID: '',
                childProtectionPlanID: '',
                childInNeedPlanID: '',
                childReferredMashID: '',
                educationPlansInPlace: [],
                referralReason: '',

                //5 Referral Reason
                socialInteractionEyeID: '',
                socialInteractionGesturesID: '',
                socialInteractionEmotionsID: '',
                restrictiveBehaviourInterestID: '',
                restrictiveBehaviourUnusualID: '',
                restrictiveBehaviourRoutineID: '',
                restrictiveBehaviourDistressID: '',
                communicationUnderstandID: '',
                communicationSpokenID: '',
                communicationFollowsID: '',
                communicationExpressionsID: '',
                attentionSelfID: '',
                attentionLedID: '',
                attentionLevelID: '',
                sensoryLoudID: '',
                sensoryTasteID: '',
                learningBehindID: '',
                learningSettledID: '',
                visionPassedID: '',
                visionConcernID: '',
                hearingPassedID: '',
                hearingConcernID: '',
                motorPassedID: '',
                motorConcernID: '',
                behaviourID: '',
                mannerisms: '',
                mannerismsComment: '',
                motorLostID: '',
                motorLostComment: '',
                learningDelayID: '',
                learningDelayComment: '',
                autFamilyID: '',
                autFamilyComment: '',
                additionalMedicalID: '',
                additionalMedicalComment: '',
                //ADHD > 5 years < 5 years and 364 days old
                isValidADHD: false,
                existingDiagnosisID: '',
                supportingDocumentsID: '',
                documents: [],
                adhdAttentionSpanID: '',
                adhdAttentionSpanComment: '',
                adhdDistractedID: '',
                adhdDistractedComment: '',
                adhdOrganisationID: '',
                adhdOrganisationComment: '',
                adhdTasksID: '',
                adhdTasksComment: '',

                adhdFidgetingID: '',
                adhdFidgetingComment: '',
                adhdChattingID: '',
                adhdChattingComment: '',
                adhdSquirmingID: '',
                adhdSquirmingComment: '',
                adhdStillID: '',
                adhdStillComment: '',
                adhdThinkingID: '',
                adhdThinkingComment: '',
                adhdConversationsID: '',
                adhdConversationsComment: '',
                adhdTurnID: '',
                adhdTurnComment: '',
                adhdRiskyID: '',
                adhdRiskyComment: '',

                additionalInformation: '',
              }}
              onSubmit={async (values) => {
                setPatientName(
                  values.childFirstName.concat(' ', values.childSurname),
                );
                setProcessing(true);
                try {
                  const res = await saveReferral(values);
                  setProcessing(false);
                  setSuccess(true);
                  setFailed(null);
                  // Add system input date and referral id for pdf use
                  values['inputDate'] = moment(res.SytemInputDate)
                    .utc()
                    .format('DD-MM-YYYY HH:mm:ss');
                  values['referralID'] = res.ReferralID;
                  values['hpref'] = null;
                  setPdfFormValues(values);
                } catch (error) {
                  setProcessing(false);
                  setFailed(error);
                }
              }}
              checkBhamPostCodes={checkBhamPostCodes}
            >
              <WizardStep validationSchema={FormValidator.patient}>
                <Patient
                  processing={processing}
                  gPRegistration={gPRegistration}
                  category={categoryRef}
                  gPPractice={gPPractice}
                  ethnicity={ethnicityRef}
                  referralType={referralTypeRef}
                />
              </WizardStep>
              <WizardStep validationSchema={FormValidator.referrer}>
                <Referrer
                  processing={processing}
                  category={categoryRef}
                  referrerTitle={referrerTitle}
                  ndtContactReference={ndtContactRef}
                />
              </WizardStep>
              <WizardStep validationSchema={FormValidator.demographics}>
                <Demographics
                  processing={processing}
                  genLanguages={genLanguages}
                  school={school}
                />
              </WizardStep>
              <WizardStep validationSchema={FormValidator.risk}>
                <Risk
                  processing={processing}
                  category={categoryRef}
                  eppRef={eppRef}
                />
              </WizardStep>
              <WizardStep validationSchema={FormValidator.referralReason}>
                <ReferralReason
                  processing={processing}
                  category={categoryRef}
                  mannerisms={mannerismsRef}
                />
              </WizardStep>
            </Wizard>
          </>
        );
      }
    }
  }
}
