import React from 'react';
import { Route, Routes, Link } from 'react-router-dom';
import NeuroForm from '../neuroForm/neuroForm.js';

export default function Routing() {
  return (
    <Routes>
      <Route path="/" element={<NeuroForm />} />
      <Route
        path="*"
        element={
          <main>
            <h1 className="mt-2 mb-4">Page not found</h1>
            <p>
              Please visit the{' '}
              <Link to="/">
                Neurodevelopmental Pathway Referral Form home page.
              </Link>
            </p>
          </main>
        }
      />
    </Routes>
  );
}
