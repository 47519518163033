import React from 'react';
import { Container, Row, Col, Alert } from 'react-bootstrap';

import BCHCLogo from '../../assets/img/nhs-logo.png';

function Header() {
  return (
    <Container>
      <header className="mb-3 mt-3">
        <Row>
          <Col xs={12} sm={12} md={8}>
            <img src={BCHCLogo} alt="BCHC logo" />
            {/* <Alert variant="info" className="mt-3">
              <h1>NEW ASD/ADHD SITE. NOT LIVE.</h1>
            </Alert> */}
          </Col>
        </Row>
      </header>
    </Container>
  );
}

export default React.memo(Header);
