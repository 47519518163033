import SelectField from '../formFields/selectField.js';
import CheckBoxField from '../formFields/checkBoxField.js';
import InputField from '../formFields/inputField.js';
import CustomFieldError from '../formFields/customFieldError.js';
import TextAreaField from '../formFields/textAreaField';
import { useFormikContext } from 'formik';
import { Form, Row, Col } from 'react-bootstrap';
import { useEffect } from 'react';

export default function Risk(props) {
  const { values, errors, touched } = useFormikContext();

  const filterYesNoNotKnown = props.category.filter(
    (x) => x.CategoriesGroup === 'YESNO' && x.ValueCode !== 'Past',
  );
  useEffect(() => {
    window.scrollTo(0, 20); // 20 prevent scrolling issues, not firing on build
  }, []);

  return (
    <>
      <Form.Group>
        <SelectField
          name={'childTheirSafetyID'}
          label={
            "The child's behaviour endangers their safety, in a way that cannot be managed*"
          }
          data={filterYesNoNotKnown}
          disabled={props.processing}
        />
        <InputField
          name="childTheirSafetyComment"
          disabled={props.processing || values.childTheirSafetyID !== 1}
          type="text"
          label="If yes, please give details"
        />
        <SelectField
          name={'childSafetyOfOthersID'}
          label={
            "The child's behaviour endangers the safety of others in a way that cannot be managed*"
          }
          data={filterYesNoNotKnown}
          disabled={props.processing}
        />
        <InputField
          name="childSafetyOfOthersComment"
          disabled={props.processing || values.childSafetyOfOthersID !== 1}
          type="text"
          label="If yes, please give details"
        />
        <SelectField
          name={'childExclusionID'}
          label={
            "The child's school / nursery has already or is considering excluding or permanently excluding them"
          }
          data={filterYesNoNotKnown}
          disabled={props.processing}
        />
        <SelectField
          name={'childAdoptionID'}
          label={'The child is being considered for adoption*'}
          data={filterYesNoNotKnown}
          disabled={props.processing}
        />
      </Form.Group>
      <Form.Group>
        <h4>Child Protection</h4>
        <SelectField
          name={'childProtectionPlanID'}
          label={'Is there a child protection plan in place*'}
          data={filterYesNoNotKnown}
          disabled={props.processing}
        />
        <SelectField
          name={'childInNeedPlanID'}
          label={'Is there a child in need plan for the child*'}
          data={filterYesNoNotKnown}
          disabled={props.processing}
        />
        <SelectField
          name={'childReferredMashID'}
          label={'Is the child referred to Safeguarding Children Services*'}
          data={filterYesNoNotKnown}
          disabled={props.processing}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Check.Label className="mb-2">
          Education Plans in Place*
        </Form.Check.Label>
        {props.eppRef.map((item, index) => (
          <CheckBoxField
            key={index}
            name={`educationPlansInPlace`}
            value={item.Id}
            label={item.Description}
            id={item.Description}
            type="checkbox"
            disabled={
              props.processing ||
              (item.Id === 7 &&
                values.educationPlansInPlace.length > 0 &&
                !values.educationPlansInPlace.includes('7')) ||
              (item.Id !== 7 &&
                values.educationPlansInPlace.length > 0 &&
                values.educationPlansInPlace.includes('7'))
            }
          />
        ))}
        <CustomFieldError name={'educationPlansInPlace'} />
      </Form.Group>
      <Form.Group className="mb-3">
        <TextAreaField
          name="referralReason"
          disabled={props.processing}
          as="textarea"
          rows={5}
          label="Reason for referral*"
          placeholder="In order to support this referral, please explain why you feel an assessment is required"
        />
      </Form.Group>
      {Object.keys(touched).length > 0 && Object.keys(errors).length > 0 && (
        <p className="customError mt-4">
          Please complete all required fields (*)
        </p>
      )}
    </>
  );
}
