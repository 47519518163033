import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';

function Footer() {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <footer className="footer">
      <Row>
        <Col xs={12} sm={12} md={4}>
          <Button variant="link" onClick={scrollToTop}>
            Scroll top
          </Button>
        </Col>
        <Col xs={12} sm={12} md={4}>
          <div className="ml-2 mt-2">
            <h4>About us</h4>
            <a
              href="https://www.bhamcommunity.nhs.uk/privacy-notices"
              target="_blank"
              rel="noreferrer"
            >
              Privacy notices
            </a>
          </div>
        </Col>
        <Col xs={12} sm={12} md={4}></Col>
      </Row>
    </footer>
  );
}

export default React.memo(Footer);
