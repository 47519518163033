import InputField from '../formFields/inputField.js';
import RadioField from '../formFields/radioField.js';
import AsyncSelectField from '../formFields/asyncSelectField.js';
import { useFormikContext } from 'formik';
import PostcodeSearch from '../formFields/address/postcodeSearch.js';
import { Form } from 'react-bootstrap';
import { useEffect } from 'react';
import CustomFieldError from '../formFields/customFieldError.js';
import SelectFieldCode from '../formFields/selectFieldCode.js';

export default function Demographics(props) {
  const { values, errors, touched } = useFormikContext();

  useEffect(() => {
    window.scrollTo(0, 20); // 20 prevent scrolling issues, not firing on build
  }, []);

  return (
    <>
      <InputField
        name="nhsNumber"
        disabled={props.processing}
        type="text"
        label="NHS No. (if known)"
      />
      <InputField
        name="parentName"
        disabled={props.processing}
        type="text"
        label="Parent Name*"
      />
      <InputField
        name="parentEmail"
        disabled={props.processing}
        type="text"
        label="Parent / Carer’s email address (if known)"
      />
      <InputField
        name="parentTelephone"
        disabled={props.processing}
        type="text"
        label="Parent / Carer’s phone number (if known)"
      />
      <p>
        <strong>Parent Address</strong>
      </p>
      <Form.Group controlId="parentAddressDiffChild" className="mb-2">
        <Form.Check.Label className="tool-hover mb-2">
          Parent address different to child?
        </Form.Check.Label>
        <RadioField
          name="parentAddressDiffChild"
          value="Yes"
          label="Yes"
          type="radio"
          disabled={props.processing}
        />
        <RadioField
          name="parentAddressDiffChild"
          value="No"
          label="No"
          type="radio"
          disabled={props.processing}
        />
      </Form.Group>
      <CustomFieldError name={'parentAddressDiffChild'} />
      <PostcodeSearch
        name="parentPostCodeSearch"
        addressFields={'parentAddress'}
        inputLabel={'Parent Address'}
        label={'Parent address postcode search'}
        placeholder={'Please enter a valid postcode to search'}
        parentAddressDiffChild={values.parentAddressDiffChild === 'No'}
      />
      <Form.Group controlId="interpreter" className="mb-2">
        <Form.Check.Label className="mb-2">
          Interpreter required*
        </Form.Check.Label>
        <RadioField
          name="interpreter"
          value="Yes"
          label="Yes"
          type="radio"
          disabled={props.processing}
        />
        <RadioField
          name="interpreter"
          value="No"
          label="No"
          type="radio"
          disabled={props.processing}
        />
      </Form.Group>
      <SelectFieldCode
        name={'interpreterGenLanguageCode'}
        label={'Interpreter Language'}
        data={props.genLanguages}
        disabled={props.processing || values.interpreter === 'No'}
      />
      <Form.Group controlId="inSchool" className="mb-2">
        <Form.Check.Label className="mb-2">
          Is child in school/nursery setting
        </Form.Check.Label>
        <RadioField
          name="inSchool"
          value="Yes"
          label="Yes"
          type="radio"
          disabled={props.processing}
        />
        <RadioField
          name="inSchool"
          data-testid="inSchoolNo"
          value="No"
          label="No"
          type="radio"
          disabled={props.processing}
        />
      </Form.Group>
      <Form.Group controlId="homeEducation" className="mb-2">
        <Form.Check.Label className="mb-2">
          Is child in home education
        </Form.Check.Label>
        <RadioField
          name="homeEducation"
          value="Yes"
          label="Yes"
          type="radio"
          disabled={props.processing || values.inSchool === 'Yes'}
        />
        <RadioField
          name="homeEducation"
          value="No"
          data-testid="homeEducationNo"
          label="No"
          type="radio"
          disabled={props.processing || values.inSchool === 'Yes'}
        />
      </Form.Group>
      <AsyncSelectField
        name={'schoolID'}
        label="Search for child School/Nursery (If your child’s school/nursery is not in the search list please select “Other” from the list and enter their school/nursery address details below)"
        popover={true}
        popoverContent={
          'If your child’s school/nursery is not in the search list please select “Other” from the list and enter their school/nursery address details below.'
        }
        data={props.school}
        optionCode={'SchoolCodeURN'}
        optionName={'SchoolName'}
        optionValue={'Id'}
        placeholder={'Search for child School/Nursery'}
        disabled={props.processing || values.inSchool === 'No'}
      />
      <InputField
        name="schoolNameOther"
        type="text"
        label="School/Nursery Name Other"
        disabled={
          props.processing ||
          values.inSchool === 'No' ||
          (values.schoolID && values.schoolID !== 514) //Other
        }
      />
      <InputField
        name="schoolNurseryAddress"
        type="text"
        label="School/Nursery Address"
        disabled={
          props.processing ||
          values.inSchool === 'No' ||
          (values.schoolID && values.schoolID !== 514) //Other
        }
      />
      <InputField
        name="schoolNurseryContactPerson"
        disabled={props.processing || values.inSchool === 'No'}
        type="text"
        label="School/Nursery Contact Person"
      />
      <InputField
        name="schoolNurseryContactPersonTel"
        disabled={props.processing || values.inSchool === 'No'}
        type="text"
        label="School/Nursery Contact Person Telephone"
      />
      <InputField
        name="schoolEmail"
        type="text"
        label="School/Nursery Email"
        disabled={props.processing || values.inSchool === 'No'}
      />
      <InputField
        name="schoolTelephone"
        disabled={
          props.processing ||
          values.inSchool === 'No' ||
          (values.schoolID && values.schoolID !== 514) //Other
        }
        type="text"
        label="School/Nursery Telephone"
      />
      {Object.keys(touched).length > 0 && Object.keys(errors).length > 0 && (
        <p className="customError mt-4">
          Please complete all required fields (*)
        </p>
      )}
    </>
  );
}
