const { hostname, protocol } = window.location;

const host =
  process.env.NODE_ENV === 'production'
    ? `https://api.bchcreferrals.nhs.uk`
    : `${protocol}//${hostname}:443`;

function send(path, token, model, method) {
  const url = host + path;

  const headers = {
    'Content-Type': 'application/json',
  };

  if (token) {
    headers['Authorization'] = `Bearer ${token}`;
  }

  const request = {
    credentials: 'omit',
    method: method === 'POST' ? 'POST' : 'GET',
    headers,
    referrer: 'no-referrer',
  };

  if (model) {
    request['body'] = JSON.stringify(model);
  }

  return new Promise((resolve, reject) => {
    fetch(url, request)
      .then((res) => {
        if (res.status === 200) {
          return res.json().then(resolve).catch(reject);
        } else if (res.status === 204) {
          return resolve(null);
        } else {
          return res.json().then(reject).catch(reject);
        }
      })
      .catch(reject);
  });
}

function pdfSend(path, token, model, method) {
  const url = host + path;

  const headers = {
    'Content-Type': 'application/json',
  };

  if (token) {
    headers['Authorization'] = `Bearer ${token}`;
  }

  const request = {
    credentials: 'omit',
    method: method === 'POST' ? 'POST' : 'GET',
    headers,
    referrer: 'no-referrer',
  };

  if (model) {
    request['body'] = JSON.stringify(model);
  }

  return new Promise((resolve, reject) => {
    fetch(url, request)
      .then((res) => {
        if (res.status === 200) {
          return res.blob().then(resolve).catch(reject);
        } else if (res.status === 204) {
          return resolve(null);
        } else {
          return res.json().then(reject).catch(reject);
        }
      })
      .catch(reject);
  });
}

export function saveReferral(values) {
  return send('/saveReferral', null, values, 'POST');
}

export function getAllReferrerTitle() {
  return send('/getAllReferrerTitle', null, null, 'GET');
}

export function getAllNdtContactReference() {
  return send('/getAllNdtContactReference', null, null, 'GET');
}

export function getAllGenLanguages() {
  return send('/getAllGenLanguage', null, null, 'GET');
}

export function getAllGPPractice() {
  return send('/getAllGPPractice', null, null, 'GET');
}

export function getAllSchools() {
  return send('/getAllSchools', null, null, 'GET');
}

export function getAllEducationPlansInPlaceReference() {
  return send('/getAllEducationPlansInPlaceReference', null, null, 'GET');
}

export function getAllCategoryReference() {
  return send('/getAllCategoryReference', null, null, 'GET');
}

export function getAllGPRegistration() {
  return send('/getAllGPRegistration', null, null, 'GET');
}

export function getAddresses_OS(postcode) {
  return send('/getOSAddressResults', null, { postcode }, 'POST');
}

export function getAllBhamPostCodes(value) {
  return send('/getAllBhamPostCodes', null, value, 'POST');
}

export function getAllMannerismsReference() {
  return send('/getAllMannerismsReference', null, null, 'GET');
}

export function getAllReferralTypeReference() {
  return send('/getAllReferralTypeReference', null, null, 'GET');
}

export function pdfNeuroReferral(value) {
  return pdfSend('/pdfNeuroReferral', null, value, 'POST');
}

export function pdfNeuroQuestions(value) {
  return pdfSend('/pdfNeuroQuestions', null, value, 'POST');
}

export function getAllEthnicityReference(value) {
  return send('/getAllEthnicityReference', null, value, 'GET');
}
