import { Form } from 'react-bootstrap';
import { useField } from 'formik';

export default function TextAreaFieldEarlyHelp({ ...props }) {
  const [field, meta] = useField(props);
  return (
    <Form.Group className="form-input-spacing" controlId={props.name}>
      <h6>**FOR ASD REFERRALS**</h6>
      <p>
        You must explain how an Early Help or graduated approach has been
        implemented over a minimum of 6 months or two school terms to support
        the child or young person. Without this evidence an Autism referral will
        be rejected.{' '}
        <a
          className="mb-2"
          href="https://www.bhamcommunity.nhs.uk/ndp-asd-referrals"
          target="_blank"
          rel="noreferrer"
        >
          For further information please visit the BCHC Neurodevelopmental
          Pathway ASD referrals web page
        </a>
      </p>
      <h6>**FOR ADHD REFERRALS**</h6>
      <p>
        Please give details below of the reasons for referral and indicate
        details of any support that has been implemented for the child or young
        person.
      </p>
      <Form.Control
        {...field}
        {...props}
        aria-required={true}
        isInvalid={meta.touched && meta.error}
      ></Form.Control>
      <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>
    </Form.Group>
  );
}
