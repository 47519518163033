import React from 'react';
import { Container } from 'react-bootstrap';
import Routing from './components/routing/routing.js';
import Header from './components/base/header.js';
import Footer from './components/base/footer.js';

export default function App() {
  return (
    <>
      <Header />
      <div className="content">
        <Container>
          <Routing />
        </Container>
      </div>
      <Footer />
    </>
  );
}
