import * as yup from 'yup';

export default function nullableNhsNo(label) {
  return yup
    .string()
    .test('NHS Number', 'Please enter a valid NHS number', (value) => {
      let isValid = true; //nullable valid to start
      const valueClean = value ? value.replace(/\s/g, '') : null;

      if (valueClean && valueClean.length === 10) {
        let total = 0;
        for (let i = 0; i < valueClean.length - 1; i++) {
          //Step 1 Multiply each of the first nine digits by a weighting factor as follows:
          let digitValue = parseInt(valueClean[i], 10);
          let weightingFactor = 10 - i;
          // Step 2 Add the results of each multiplication together.
          total += digitValue * weightingFactor;
        }

        //Step 3 Divide the total by 11 and establish the remainder.
        // Step 4 Subtract the remainder from 11 to give the check digi
        let establishRemainder = 11 - (total % 11);

        //If the result is 11 then a check digit of 0 is used.
        if (establishRemainder === 11) {
          establishRemainder = 0;
        }
        //Step 5 Check the remainder matches the check digit (The tenth digit is a check digit used to confirm its validity.).

        if (establishRemainder === parseInt(valueClean.slice(-1), 10)) {
          isValid = true;
        } else {
          isValid = false;
        }
      }

      if (valueClean && (valueClean.length < 10 || valueClean.length > 10)) {
        isValid = false;
      }
      //Field is nullable, valid if no input
      if (
        valueClean === '' ||
        valueClean === undefined ||
        valueClean === null
      ) {
        isValid = true;
      }
      return isValid;
    })
    .label(label)
    .typeError(`Please enter a valid NHS number`)
    .nullable();
}
