import InputField from '../formFields/inputField.js';
import SelectField from '../formFields/selectField.js';
import AsyncSelectField from '../formFields/asyncSelectField.js';
import { useFormikContext } from 'formik';
import PostcodeSearch from '../formFields/address/postcodeSearch.js';
import DatePickerField from '../formFields/datepickerField.js';
import { useEffect } from 'react';
import TextAreaFieldEarlyHelp from '../formFields/textAreaFieldEarlyHelp.js';

export default function Patient(props) {
  const { values, errors, touched } = useFormikContext();

  const filterResiding = props.category.filter(
    (x) =>
      x.CategoriesGroup === 'PARENT' &&
      x.ValueCode !== 'Mother' &&
      x.ValueCode !== 'Father',
  );

  useEffect(() => {
    window.scrollTo(0, 20); // 20 prevent scrolling issues, not firing on build
  }, []);

  return (
    <>
      <InputField
        name="childFirstName"
        type="text"
        label="Child's First Name*"
        disabled={props.processing}
      />
      <InputField
        name="childSurname"
        disabled={props.processing}
        type="text"
        label="Child's Last Name*"
      />
      <DatePickerField
        className="mt-2"
        disabled={props.processing}
        label={'Child Date of Birth* (For example 15/03/2010)'}
        name="childDOB"
        placeholderText="DD/MM/YYYY"
      />

      <SelectField
        name={'referralTypeID'}
        label={'Do you wish to refer the child to*'}
        data={props.referralType}
        disabled={props.processing}
      />

      <SelectField
        name={'ethnicityID'}
        label="Child's Ethnicity*"
        data={props.ethnicity}
        disabled={props.processing}
      />

      <TextAreaFieldEarlyHelp
        name="earlyHelp"
        disabled={props.processing}
        as="textarea"
        rows={5}
      />

      <SelectField
        name={'childResidingID'}
        label={'Is the child residing with*'}
        data={filterResiding}
        disabled={props.processing}
      />

      <InputField
        name="socialWorkerName"
        disabled={props.processing || values.childResidingID !== 45}
        type="text"
        label="Social Workers Name"
      />

      <InputField
        name="socialWorkerTel"
        disabled={props.processing || values.childResidingID !== 45}
        type="text"
        label="Social Worker telephone contact no."
      />

      <InputField
        name="socialWorkerEmail"
        disabled={props.processing || values.childResidingID !== 45}
        type="text"
        label="Social Worker email address"
      />

      <InputField
        name="socialWorkerPlacement"
        disabled={props.processing || values.childResidingID !== 45}
        type="text"
        label="How long as the child been at their current placement"
      />

      <PostcodeSearch
        name="childPostCodeSearch"
        inputLabel={'Child Address'}
        addressFields={'childAddress'}
        label={'Child address postcode search'}
        placeholder={'Please enter a valid postcode to search'}
      />

      <SelectField
        name={'gPRegistrationID'}
        label={'Is child registered with a GP?*'}
        data={props.gPRegistration}
        disabled={props.processing}
      />
      <AsyncSelectField
        name={'gPPracticeID'}
        label="Search for child’s Birmingham GP by practice code or practice name"
        data={props.gPPractice}
        popover={false}
        // popoverContent={
        //   'If the GP you are searching for is not shown, please contact 0121 *** ****.'
        // }
        optionCode={'GPPracticeCode_Current'}
        optionName={'GPPracticeName_Current'}
        optionValue={'Id'}
        placeholder={
          'Search for child’s Birmingham GP by practice code or practice name'
        }
        disabled={props.processing || values.gPRegistrationID !== 1}
      />
      {Object.keys(touched).length > 0 && Object.keys(errors).length > 0 && (
        <p className="customError mt-4">
          Please complete all required fields (*)
        </p>
      )}
    </>
  );
}
