import * as yup from 'yup';
import moment from 'moment';

import {
  stringMinMaxRequired,
  nullableStringMax,
  postcode,
  intRequired,
  stringRequired,
} from '../fields/index.js';

export default yup.object().shape({
  childFirstName: stringMinMaxRequired('This information is required', 1, 255),
  childSurname: stringMinMaxRequired('This information is required', 1, 255),

  childDOB: yup
    .date()
    .test({
      message:
        'For ASD, referred child must be aged between 2 years and 6 months old and 15 years and 364 days old. For ADHD, child must be aged between 5 years and 0 months old and 15 years and 364 days old',
      test: function (value) {
        if (
          value &&
          moment().diff(moment(value), 'years') < 16 &&
          moment().diff(moment(value), 'months') >= 30
        ) {
          return true;
        } else {
          return false;
        }
      },
    })
    .typeError(
      'For ASD, referred child must be aged between 2 years and 6 months old and 15 years and 364 days old. For ADHD, child must be aged between 5 years and 0 months old and 15 years and 364 days old',
    ),

  referralTypeID: yup
    .number()
    .integer()
    .test({
      test: function (value) {
        if (!value) {
          return this.createError({
            path: 'referralTypeID',
            message: 'This information is required',
          });
        } else if (
          // SR change 16.11 to just 1 "ADHD" and 2 "ASD"
          // Valid if value selected 2 or 4 (ASD or Not known), ADHD or ADHD/ASD selected less that 16 years, greater than 5
          value === 2 ||
          value === 4 ||
          (value === 1 &&
            this.parent.childDOB &&
            moment().diff(moment(this.parent.childDOB), 'years') < 16 &&
            moment().diff(moment(this.parent.childDOB), 'years') >= 5) ||
          (value === 3 &&
            this.parent.childDOB &&
            moment().diff(moment(this.parent.childDOB), 'years') < 16 &&
            moment().diff(moment(this.parent.childDOB), 'years') >= 5)
        ) {
          return true;
        } else {
          return this.createError({
            path: 'referralTypeID',
            message:
              'For ADHD, child is aged between 5 years and 0 months old and 15 years and 364 days old',
          });
        }
      },
    }),

  ethnicityID: intRequired('This information is required'),
  earlyHelp: stringRequired('This information is required'),
  childResidingID: intRequired('This information is required'),
  socialWorkerName: yup.string().test({
    test: function (value) {
      if (this.parent.childResidingID === 45 && !value) {
        return this.createError({
          path: 'socialWorkerName',
          message:
            'Selected child residing with Local Authority. This information is required',
        });
      } else if (this.parent.childResidingID === 45 && value) {
        let validLength = yup.string().max(500).isValidSync(value);
        if (validLength) {
          return true;
        } else {
          return this.createError({
            path: 'socialWorkerName',
            message:
              'Selected child residing with Local Authority. Text length cannot be greater than 500 characters.',
          });
        }
      } else {
        return true;
      }
    },
  }),

  socialWorkerTel: yup.string().test({
    test: function (value) {
      if (this.parent.childResidingID === 45 && !value) {
        return this.createError({
          path: 'socialWorkerTel',
          message:
            'Selected child residing with Local Authority. This information is required',
        });
      } else if (this.parent.childResidingID === 45 && value) {
        const tel = /^0\d{8,16}$/;
        const valueClean = value ? value.replace(/\s/g, '') : null;
        if (tel.test(valueClean)) {
          return true;
        } else {
          return this.createError({
            path: 'socialWorkerTel',
            message:
              'Selected child residing with Local Authority. Telephone number is invalid',
          });
        }
      } else {
        return true;
      }
    },
  }),

  socialWorkerEmail: yup.string().test({
    test: function (value) {
      if (this.parent.childResidingID === 45 && !value) {
        return this.createError({
          path: 'socialWorkerEmail',
          message:
            'Selected child residing with Local Authority. This information is required',
        });
      } else if (this.parent.childResidingID === 45 && value) {
        let validEmail = yup.string().email().isValidSync(value);

        if (validEmail) {
          return true;
        } else {
          return this.createError({
            path: 'socialWorkerEmail',
            message:
              'Selected child residing with Local Authority. Email is invalid',
          });
        }
      } else {
        return true;
      }
    },
  }),

  socialWorkerPlacement: yup.string().test({
    test: function (value) {
      if (this.parent.childResidingID === 45 && !value) {
        return this.createError({
          path: 'socialWorkerPlacement',
          message:
            'Selected child residing with Local Authority. This information is required',
        });
      } else if (this.parent.childResidingID === 45 && value) {
        let validLength = yup.string().max(500).isValidSync(value);
        if (validLength) {
          return true;
        } else {
          return this.createError({
            path: 'socialWorkerPlacement',
            message:
              'Selected child residing with Local Authority. Text length cannot be greater than 500 characters.',
          });
        }
      } else {
        return true;
      }
    },
  }),

  childAddressLine1: stringMinMaxRequired(
    'This information is required',
    1,
    500,
  ),
  childAddressLine2: stringMinMaxRequired(
    'This information is required',
    1,
    500,
  ),
  childAddressLine3: nullableStringMax('Incorrect format', 500),
  childAddressLine4: stringMinMaxRequired(
    'This information is required',
    1,
    500,
  ),
  childAddressPostcode: postcode(
    'This information is required. Please enter a valid postcode',
  ),

  gPRegistrationID: yup.number().test({
    test: function (value) {
      if (value === 1) {
        return true;
      }
      if (value === 2) {
        return this.createError({
          path: 'gPRegistrationID',
          message:
            'The BCHC neurodevelopmental pathway service can only accept referrals for children registered with Birmingham GPs.  Please liaise with the child’s GP to identify appropriate services in their area.',
        });
      } else if (value === 3) {
        return true;
        // return this.createError({
        //   path: 'gPRegistrationID',
        //   message:
        //     'The BCHC neurodevelopmental pathway service can only accept referrals for children registered with Birmingham GPs or who are resident in the Birmingham area if they are not registered with a GP. Please encourage the parent to register their child with their local GP.',
        // });
      } else {
        return this.createError({
          path: 'gPRegistrationID',
          message: 'This information is required',
        });
      }
    },
  }),

  gPPracticeID: yup.number().test({
    test: function (value) {
      if (this.parent.gPRegistrationID === 1 && !value) {
        return this.createError({
          path: 'gPPracticeID',
          message: 'This information is required',
        });
      } else {
        return true;
      }
    },
  }),
});
