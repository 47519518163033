import * as yup from 'yup';

import {
  stringMinMaxRequired,
  nullableStringMax,
  postcode,
  nullableNhsNo,
  nullableEmail,
  nullableTelephone,
  stringRequired,
  detailsString,
} from '../fields/index.js';

export default yup.object().shape({
  nhsNumber: nullableNhsNo('Please enter a valid NHS number'),
  parentName: stringMinMaxRequired('This information is required', 1, 500),
  parentEmail: nullableEmail('Please enter a valid email address'),
  parentTelephone: nullableTelephone('Please enter a valid telephone'),
  parentAddressDiffChild: stringRequired(
    'Parent address different to child, this information is required',
  ),
  parentAddressLine1: detailsString(
    'parentAddressDiffChild',
    stringMinMaxRequired('This information is required', 1, 500),
    'Yes',
  ),
  parentAddressLine2: detailsString(
    'parentAddressDiffChild',
    stringMinMaxRequired('This information is required', 1, 500),
    'Yes',
  ),
  parentAddressLine3: detailsString(
    'parentAddressDiffChild',
    nullableStringMax('Incorrect format', 500),
    'Yes',
  ),
  parentAddressLine4: detailsString(
    'parentAddressDiffChild',
    stringMinMaxRequired('This information is required', 1, 500),
    'Yes',
  ),
  parentAddressPostcode: detailsString(
    'parentAddressDiffChild',
    postcode('Please enter a valid postcode'),
    'Yes',
  ),
  interpreter: stringRequired('This information is required'),
  interpreterGenLanguageCode: detailsString(
    'interpreter',
    stringRequired('Selected YES. This information is required'),
    'Yes',
  ),

  inSchool: stringRequired('Please confirm school setting'),
  homeEducation: stringRequired('Please confirm home education'),
  schoolID: yup.number().test({
    test: function (value) {
      if (this.parent.inSchool === 'Yes' && !value) {
        return this.createError({
          path: 'schoolID',
          message: 'This information is required',
        });
      } else {
        return true;
      }
    },
  }),
  schoolNameOther: yup.string().test({
    test: function (value) {
      if (this.parent.schoolID === 514 && !value) {
        return this.createError({
          path: 'schoolNameOther',
          message: 'Selected Other School. Please provide a school name',
        });
      } else if (value) {
        let validLength = yup.string().max(500).isValidSync(value);
        if (validLength) {
          return true;
        } else {
          return this.createError({
            path: 'schoolNameOther',
            message:
              'Selected Other School. Text length cannot be greater than 500 characters.',
          });
        }
      } else {
        return true;
      }
    },
  }),

  schoolNurseryAddress: yup.string().test({
    test: function (value) {
      if (this.parent.schoolID === 514 && !value) {
        return this.createError({
          path: 'schoolNurseryAddress',
          message: 'Selected Other School. Please provide a school address',
        });
      } else if (value) {
        let validLength = yup.string().max(500).isValidSync(value);
        if (validLength) {
          return true;
        } else {
          return this.createError({
            path: 'schoolNurseryAddress',
            message:
              'Selected Other School. Text length cannot be greater than 500 characters.',
          });
        }
      } else {
        return true;
      }
    },
  }),

  schoolNurseryContactPerson: nullableStringMax('Incorrect format', 500),
  schoolNurseryContactPersonTel: nullableTelephone(
    'Please enter a valid telephone',
  ),
  schoolEmail: nullableEmail('Please enter a valid email address'),
  schoolTelephone: nullableTelephone('Please enter a valid telephone'),
});
