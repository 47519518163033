import React from 'react';
import { Accordion } from 'react-bootstrap';

export default function AccordionComponent(props) {
  return (
    <Accordion flush>
      <Accordion.Item eventKey="0">
        <Accordion.Header>{props.titleBodyText}</Accordion.Header>
        <Accordion.Body>{props.accordionBodyText}</Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}
