import { useField } from 'formik';

export default function CustomFieldErrorLink({ label, ...props }) {
  const [field, meta] = useField(props);

  return (
    <>
      {meta.touched && meta.error && (
        <div>
          <p className="customError">
            {meta.error}{' '}
            <a target="_blank" rel="noreferrer" href={props.link}>
              {props.linkText}
            </a>
          </p>
        </div>
      )}
    </>
  );
}
