import { Form, Button, Table } from 'react-bootstrap';
import { useField } from 'formik';
import { useState, useEffect, useRef } from 'react';

import { nanoid } from 'nanoid';

import pdfIcon from '../../assets/icons/pdf.svg';
import docIcon from '../../assets/icons/doc.svg';
import docxIcon from '../../assets/icons/docx.svg';
import emptyIcon from '../../assets/icons/emptyFile.svg';

export default function DocumentUploadField({ label, ...props }) {
  const [field, meta, helper] = useField(props);
  const { setValue } = helper;
  const fileInput = useRef(null);
  const [selectedDocs, setSelectedDocs] = useState(field.value);
  const [fileReadError, setFileReaderError] = useState(false);

  const base64Conversion = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        let base64str = fileReader.result;
        let position = base64str.search(',');
        let x = base64str.substring(0, position + 1);
        let result = base64str.replace(x, '');
        resolve(result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleDocSelect = async (e) => {
    const docs = Array.from(e.target.files);

    for (let d of docs) {
      try {
        const base64 = await base64Conversion(d);
        d['base64'] = base64;
        d['id'] = nanoid();
        setFileReaderError(false);
      } catch {
        setFileReaderError(true);
      }
    }

    const documents = docs.map((x) => ({
      id: x.id,
      name: x.name,
      type: x.type,
      size: x.size,
      base64: x.base64,
    }));

    setSelectedDocs((x) => [...x, ...documents]);
    setValue(documents);
  };

  const handleDocRemove = (doc, e) => {
    setSelectedDocs((x) => x.filter((b) => b.id !== doc.id));

    fileInput.current.value = null;
  };

  useEffect(() => {
    setValue(selectedDocs);
  }, [selectedDocs]);

  return (
    <>
      <Form.Group className="form-input-spacing" controlId={props.name}>
        <Form.Label>{label}</Form.Label>
        <Form.Control
          type="file"
          title=""
          ref={fileInput}
          multiple
          isInvalid={meta.touched && meta.error}
          onChange={handleDocSelect}
          name={props.name}
          disabled={props.disabled}
        />
        {fileReadError && (
          <p className="customError">Error reading file upload, please retry</p>
        )}
        <Form.Control.Feedback type="invalid">
          {meta.error}
        </Form.Control.Feedback>
      </Form.Group>
      <div>
        <Table responsive="sm" id="documentUpload">
          <thead>
            <tr>
              <th>Document</th>
              <th>Type</th>
              <th>Size</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {selectedDocs.length ? (
              selectedDocs.map((x, index) => (
                <tr key={`el_${index}`}>
                  <td>{x.name}</td>
                  <td>
                    <img
                      src={
                        x.name.split('.').pop().toLowerCase() === 'pdf'
                          ? pdfIcon
                          : x.name.split('.').pop().toLowerCase() === 'docx'
                          ? docxIcon
                          : x.name.split('.').pop().toLowerCase() === 'doc'
                          ? docIcon
                          : emptyIcon
                      }
                      alt="information-icon"
                      className="icon-size"
                    />
                  </td>
                  <td>{(((x.size / (1024 * 1024)) * 10) / 10).toFixed(2)}mb</td>
                  <td>
                    <Button
                      size="sm"
                      variant="danger"
                      onClick={(a) => handleDocRemove(x, a)}
                    >
                      Remove
                    </Button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td>No documents uploaded</td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    </>
  );
}
