import * as yup from 'yup';
import {
  intRequired,
  nullableString,
  arrayContains,
  stringRequired,
  stringMinMaxRequired,
} from '../fields/index.js';

export default yup.object().shape({
  socialInteractionEyeID: intRequired('This information is required'),
  socialInteractionGesturesID: intRequired('This information is required'),
  socialInteractionEmotionsID: intRequired('This information is required'),
  restrictiveBehaviourInterestID: intRequired('This information is required'),
  restrictiveBehaviourUnusualID: intRequired('This information is required'),
  restrictiveBehaviourRoutineID: intRequired('This information is required'),
  restrictiveBehaviourDistressID: intRequired('This information is required'),
  communicationUnderstandID: intRequired('This information is required'),
  communicationSpokenID: intRequired('This information is required'),
  communicationFollowsID: intRequired('This information is required'),
  communicationExpressionsID: intRequired('This information is required'),
  attentionSelfID: intRequired('This information is required'),
  attentionLedID: intRequired('Attention led is required'),
  attentionLevelID: intRequired('This information is required'),
  sensoryLoudID: intRequired('This information is required'),
  sensoryTasteID: intRequired('This information is required'),
  learningBehindID: intRequired('This information is required'),
  learningSettledID: intRequired('This information is required'),
  visionPassedID: intRequired('This information is required'),
  visionConcernID: intRequired('This information is required'),
  hearingPassedID: intRequired('This information is required'),
  hearingConcernID: intRequired('This information is required'),
  motorPassedID: intRequired('This information is required'),
  motorConcernID: intRequired('This information is required'),
  behaviourID: intRequired('This information is required'),
  mannerisms: arrayContains('This information is required'),
  mannerismsComment: yup.string().when('mannerisms', {
    is: (mannerisms) =>
      // 7 is other
      mannerisms && mannerisms.includes('7'),
    then: stringMinMaxRequired(
      'Selected Other. This information is required',
      1,
      500,
    ),
  }),

  motorLostID: intRequired('This information is required'),
  motorLostComment: yup.string().test({
    test: function (value) {
      if (this.parent.motorLostID === 1 && !value) {
        return this.createError({
          path: 'motorLostComment',
          message: 'Selected YES. This information is required',
        });
      } else if (this.parent.motorLostID === 1 && value) {
        let validLength = yup.string().max(500).isValidSync(value);
        if (validLength) {
          return true;
        } else {
          return this.createError({
            path: 'motorLostComment',
            message:
              'Selected YES. Text length cannot be greater than 500 characters.',
          });
        }
      } else {
        return true;
      }
    },
  }),
  learningDelayID: intRequired('This information is required'),
  learningDelayComment: yup.string().test({
    test: function (value) {
      if (this.parent.learningDelayID === 1 && !value) {
        return this.createError({
          path: 'learningDelayComment',
          message: 'Selected YES. This information is required',
        });
      } else if (this.parent.learningDelayID === 1 && value) {
        let validLength = yup.string().max(500).isValidSync(value);
        if (validLength) {
          return true;
        } else {
          return this.createError({
            path: 'learningDelayComment',
            message:
              'Selected YES. Text length cannot be greater than 500 characters.',
          });
        }
      } else {
        return true;
      }
    },
  }),

  autFamilyID: intRequired('This information is required'),
  autFamilyComment: yup.string().test({
    test: function (value) {
      if (this.parent.autFamilyID === 1 && !value) {
        return this.createError({
          path: 'autFamilyComment',
          message: 'Selected YES. This information is required',
        });
      } else if (this.parent.autFamilyID === 1 && value) {
        let validLength = yup.string().max(500).isValidSync(value);
        if (validLength) {
          return true;
        } else {
          return this.createError({
            path: 'autFamilyComment',
            message:
              'Selected YES. Text length cannot be greater than 500 characters.',
          });
        }
      } else {
        return true;
      }
    },
  }),

  existingDiagnosisID: intRequired('This information is required'),
  supportingDocumentsID: yup.string().test({
    test: function (value) {
      if (
        (this.parent.existingDiagnosisID === 49 && !value) ||
        (this.parent.existingDiagnosisID === 50 && !value) ||
        (this.parent.existingDiagnosisID === 51 && !value)
      ) {
        return this.createError({
          path: 'supportingDocumentsID',
          message: 'Selected Diagnosis. This information is required',
        });
      } else {
        return true;
      }
    },
  }),

  //supportingDocumentsID: intRequired('This information is required'),
  documents: yup.array().when('supportingDocumentsID', {
    is: '1',
    then: yup
      .array()
      .test(
        'Unique',
        'Duplicate document submitted, please amend.',
        (value) => {
          if (value) {
            //Map all file names
            const fileNames = value.map((x) => x.name);
            //Create a new set of the file names, Set only allows unique values
            const uniqueFileNames = new Set(fileNames);
            //If the filename and unique file names have the same length return true as they are no duplicates

            return fileNames.length === uniqueFileNames.size;
          }
          return true;
        },
      )
      .test(
        'Empty',
        'Please provide at least one supporting document.',
        (value) => {
          if (Array.isArray(value) && !value.length) {
            return false;
          }
          return true;
        },
      )
      .test(
        'fileType',
        'Only PDF and DOC files are allowed, please amend.',
        (value) => {
          if (value) {
            return value.every((file) => {
              const fileType = file.name.split('.').pop().toLowerCase();
              return ['pdf', 'doc', 'docx'].includes(fileType);
            });
          }
          return true;
        },
      )
      .test(
        'filesize',
        'Totle file size is greater than 1mb, please amend.',
        (value) => {
          if (value) {
            const totalSize = value.reduce((acc, file) => acc + file.size, 0);
            return totalSize <= 1024 * 1024;
          }
          return true;
        },
      )
      .required('Please provide at least one supporting document.'),
  }),

  adhdAttentionSpanID: yup
    .number()
    .integer()
    .test({
      test: function (value) {
        if (this.parent.isValidADHD && !value) {
          return this.createError({
            path: 'adhdAttentionSpanID',
            message: 'This information is required',
          });
        } else {
          return true;
        }
      },
    }),
  adhdAttentionSpanComment: yup.string().test({
    test: function (value) {
      if (
        this.parent.adhdAttentionSpanID === 1 &&
        !value &&
        this.parent.isValidADHD
      ) {
        return this.createError({
          path: 'adhdAttentionSpanComment',
          message: 'Selected YES. This information is required',
        });
      } else if (
        this.parent.adhdAttentionSpanID === 1 &&
        value &&
        this.parent.isValidADHD
      ) {
        let validLength = yup.string().max(500).isValidSync(value);
        if (validLength) {
          return true;
        } else {
          return this.createError({
            path: 'adhdAttentionSpanComment',
            message:
              'Selected YES. Text length cannot be greater than 500 characters.',
          });
        }
      } else {
        return true;
      }
    },
  }),

  adhdDistractedID: yup
    .number()
    .integer()
    .test({
      test: function (value) {
        if (this.parent.isValidADHD && !value) {
          return this.createError({
            path: 'adhdDistractedID',
            message: 'This information is required',
          });
        } else {
          return true;
        }
      },
    }),
  adhdDistractedComment: yup.string().test({
    test: function (value) {
      if (
        this.parent.adhdDistractedID === 1 &&
        !value &&
        this.parent.isValidADHD
      ) {
        return this.createError({
          path: 'adhdDistractedComment',
          message: 'Selected YES. This information is required',
        });
      } else if (
        this.parent.adhdDistractedID === 1 &&
        value &&
        this.parent.isValidADHD
      ) {
        let validLength = yup.string().max(500).isValidSync(value);
        if (validLength) {
          return true;
        } else {
          return this.createError({
            path: 'adhdDistractedComment',
            message:
              'Selected YES. Text length cannot be greater than 500 characters.',
          });
        }
      } else {
        return true;
      }
    },
  }),

  adhdOrganisationID: yup
    .number()
    .integer()
    .test({
      test: function (value) {
        if (this.parent.isValidADHD && !value) {
          return this.createError({
            path: 'adhdOrganisationID',
            message: 'This information is required',
          });
        } else {
          return true;
        }
      },
    }),
  adhdOrganisationComment: yup.string().test({
    test: function (value) {
      if (
        this.parent.adhdOrganisationID === 1 &&
        !value &&
        this.parent.isValidADHD
      ) {
        return this.createError({
          path: 'adhdOrganisationComment',
          message: 'Selected YES. This information is required',
        });
      } else if (
        this.parent.adhdOrganisationID === 1 &&
        value &&
        this.parent.isValidADHD
      ) {
        let validLength = yup.string().max(500).isValidSync(value);
        if (validLength) {
          return true;
        } else {
          return this.createError({
            path: 'adhdOrganisationComment',
            message:
              'Selected YES. Text length cannot be greater than 500 characters.',
          });
        }
      } else {
        return true;
      }
    },
  }),

  adhdTasksID: yup
    .number()
    .integer()
    .test({
      test: function (value) {
        if (this.parent.isValidADHD && !value) {
          return this.createError({
            path: 'adhdTasksID',
            message: 'This information is required',
          });
        } else {
          return true;
        }
      },
    }),
  adhdTasksComment: yup.string().test({
    test: function (value) {
      if (this.parent.adhdTasksID === 1 && !value && this.parent.isValidADHD) {
        return this.createError({
          path: 'adhdTasksComment',
          message: 'Selected YES. This information is required',
        });
      } else if (
        this.parent.adhdTasksID === 1 &&
        value &&
        this.parent.isValidADHD
      ) {
        let validLength = yup.string().max(500).isValidSync(value);
        if (validLength) {
          return true;
        } else {
          return this.createError({
            path: 'adhdTasksComment',
            message:
              'Selected YES. Text length cannot be greater than 500 characters.',
          });
        }
      } else {
        return true;
      }
    },
  }),

  adhdFidgetingID: yup
    .number()
    .integer()
    .test({
      test: function (value) {
        if (this.parent.isValidADHD && !value) {
          return this.createError({
            path: 'adhdFidgetingID',
            message: 'This information is required',
          });
        } else {
          return true;
        }
      },
    }),
  adhdFidgetingComment: yup.string().test({
    test: function (value) {
      if (
        this.parent.adhdFidgetingID === 1 &&
        !value &&
        this.parent.isValidADHD
      ) {
        return this.createError({
          path: 'adhdFidgetingComment',
          message: 'Selected YES. This information is required',
        });
      } else if (
        this.parent.adhdFidgetingID === 1 &&
        value &&
        this.parent.isValidADHD
      ) {
        let validLength = yup.string().max(500).isValidSync(value);
        if (validLength) {
          return true;
        } else {
          return this.createError({
            path: 'adhdFidgetingComment',
            message:
              'Selected YES. Text length cannot be greater than 500 characters.',
          });
        }
      } else {
        return true;
      }
    },
  }),

  adhdChattingID: yup
    .number()
    .integer()
    .test({
      test: function (value) {
        if (this.parent.isValidADHD && !value) {
          return this.createError({
            path: 'adhdChattingID',
            message: 'This information is required',
          });
        } else {
          return true;
        }
      },
    }),
  adhdChattingComment: yup.string().test({
    test: function (value) {
      if (
        this.parent.adhdChattingID === 1 &&
        !value &&
        this.parent.isValidADHD
      ) {
        return this.createError({
          path: 'adhdChattingComment',
          message: 'Selected YES. This information is required',
        });
      } else if (
        this.parent.adhdChattingID === 1 &&
        value &&
        this.parent.isValidADHD
      ) {
        let validLength = yup.string().max(500).isValidSync(value);
        if (validLength) {
          return true;
        } else {
          return this.createError({
            path: 'adhdChattingComment',
            message:
              'Selected YES. Text length cannot be greater than 500 characters.',
          });
        }
      } else {
        return true;
      }
    },
  }),

  adhdSquirmingID: yup
    .number()
    .integer()
    .test({
      test: function (value) {
        if (this.parent.isValidADHD && !value) {
          return this.createError({
            path: 'adhdSquirmingID',
            message: 'This information is required',
          });
        } else {
          return true;
        }
      },
    }),
  adhdSquirmingComment: yup.string().test({
    test: function (value) {
      if (
        this.parent.adhdSquirmingID === 1 &&
        !value &&
        this.parent.isValidADHD
      ) {
        return this.createError({
          path: 'adhdSquirmingComment',
          message: 'Selected YES. This information is required',
        });
      } else if (
        this.parent.adhdSquirmingID === 1 &&
        value &&
        this.parent.isValidADHD
      ) {
        let validLength = yup.string().max(500).isValidSync(value);
        if (validLength) {
          return true;
        } else {
          return this.createError({
            path: 'adhdSquirmingComment',
            message:
              'Selected YES. Text length cannot be greater than 500 characters.',
          });
        }
      } else {
        return true;
      }
    },
  }),

  adhdStillID: yup
    .number()
    .integer()
    .test({
      test: function (value) {
        if (this.parent.isValidADHD && !value) {
          return this.createError({
            path: 'adhdStillID',
            message: 'This information is required',
          });
        } else {
          return true;
        }
      },
    }),
  adhdStillComment: yup.string().test({
    test: function (value) {
      if (this.parent.adhdStillID === 1 && !value && this.parent.isValidADHD) {
        return this.createError({
          path: 'adhdStillComment',
          message: 'Selected YES. This information is required',
        });
      } else if (
        this.parent.adhdStillID === 1 &&
        value &&
        this.parent.isValidADHD
      ) {
        let validLength = yup.string().max(500).isValidSync(value);
        if (validLength) {
          return true;
        } else {
          return this.createError({
            path: 'adhdStillComment',
            message:
              'Selected YES. Text length cannot be greater than 500 characters.',
          });
        }
      } else {
        return true;
      }
    },
  }),

  adhdThinkingID: yup
    .number()
    .integer()
    .test({
      test: function (value) {
        if (this.parent.isValidADHD && !value) {
          return this.createError({
            path: 'adhdThinkingID',
            message: 'This information is required',
          });
        } else {
          return true;
        }
      },
    }),
  adhdThinkingComment: yup.string().test({
    test: function (value) {
      if (
        this.parent.adhdThinkingID === 1 &&
        !value &&
        this.parent.isValidADHD
      ) {
        return this.createError({
          path: 'adhdThinkingComment',
          message: 'Selected YES. This information is required',
        });
      } else if (
        this.parent.adhdThinkingID === 1 &&
        value &&
        this.parent.isValidADHD
      ) {
        let validLength = yup.string().max(500).isValidSync(value);
        if (validLength) {
          return true;
        } else {
          return this.createError({
            path: 'adhdThinkingComment',
            message:
              'Selected YES. Text length cannot be greater than 500 characters.',
          });
        }
      } else {
        return true;
      }
    },
  }),

  adhdConversationsID: yup
    .number()
    .integer()
    .test({
      test: function (value) {
        if (this.parent.isValidADHD && !value) {
          return this.createError({
            path: 'adhdConversationsID',
            message: 'This information is required',
          });
        } else {
          return true;
        }
      },
    }),
  adhdConversationsComment: yup.string().test({
    test: function (value) {
      if (
        this.parent.adhdConversationsID === 1 &&
        !value &&
        this.parent.isValidADHD
      ) {
        return this.createError({
          path: 'adhdConversationsComment',
          message: 'Selected YES. This information is required',
        });
      } else if (
        this.parent.adhdConversationsID === 1 &&
        value &&
        this.parent.isValidADHD
      ) {
        let validLength = yup.string().max(500).isValidSync(value);
        if (validLength) {
          return true;
        } else {
          return this.createError({
            path: 'adhdConversationsComment',
            message:
              'Selected YES. Text length cannot be greater than 500 characters.',
          });
        }
      } else {
        return true;
      }
    },
  }),

  adhdTurnID: yup
    .number()
    .integer()
    .test({
      test: function (value) {
        if (this.parent.isValidADHD && !value) {
          return this.createError({
            path: 'adhdTurnID',
            message: 'This information is required',
          });
        } else {
          return true;
        }
      },
    }),
  adhdTurnComment: yup.string().test({
    test: function (value) {
      if (this.parent.adhdTurnID === 1 && !value && this.parent.isValidADHD) {
        return this.createError({
          path: 'adhdTurnComment',
          message: 'Selected YES. This information is required',
        });
      } else if (
        this.parent.adhdTurnID === 1 &&
        value &&
        this.parent.isValidADHD
      ) {
        let validLength = yup.string().max(500).isValidSync(value);
        if (validLength) {
          return true;
        } else {
          return this.createError({
            path: 'adhdTurnComment',
            message:
              'Selected YES. Text length cannot be greater than 500 characters.',
          });
        }
      } else {
        return true;
      }
    },
  }),

  adhdRiskyID: yup
    .number()
    .integer()
    .test({
      test: function (value) {
        if (this.parent.isValidADHD && !value) {
          return this.createError({
            path: 'adhdRiskyID',
            message: 'This information is required',
          });
        } else {
          return true;
        }
      },
    }),
  adhdRiskyComment: yup.string().test({
    test: function (value) {
      if (this.parent.adhdRiskyID === 1 && !value && this.parent.isValidADHD) {
        return this.createError({
          path: 'adhdRiskyComment',
          message: 'Selected YES. This information is required',
        });
      } else if (
        this.parent.adhdRiskyID === 1 &&
        value &&
        this.parent.isValidADHD
      ) {
        let validLength = yup.string().max(500).isValidSync(value);
        if (validLength) {
          return true;
        } else {
          return this.createError({
            path: 'adhdRiskyComment',
            message:
              'Selected YES. Text length cannot be greater than 500 characters.',
          });
        }
      } else {
        return true;
      }
    },
  }),

  // Referral type values
  //   {
  //     "Id": 1,
  //     "Description": "ADHD",
  // },
  // {
  //     "Id": 2,
  //     "Description": "ASD",
  // },
  // {
  //     "Id": 3,
  //     "Description": "Both ADHD and ASD",
  // },
  // {
  //    Id": 4,
  //     "Description": "Not known",
  // }

  additionalMedicalID: intRequired('This information is required'),
  additionalMedicalComment: yup.string().test({
    test: function (value) {
      if (this.parent.additionalMedicalID === 1 && !value) {
        return this.createError({
          path: 'additionalMedicalComment',
          message: 'Selected YES. This information is required',
        });
      } else if (
        this.parent.additionalMedicalID === 1 &&
        value &&
        this.parent.isValidADHD
      ) {
        let validLength = yup.string().max(500).isValidSync(value);
        if (validLength) {
          return true;
        } else {
          return this.createError({
            path: 'additionalMedicalComment',
            message:
              'Selected YES. Text length cannot be greater than 500 characters.',
          });
        }
      } else {
        return true;
      }
    },
  }),

  additionalInformation: nullableString('Incorrect format'),
});
