import { Form } from 'react-bootstrap';
import SelectField from '../formFields/selectField.js';
import TextAreaField from '../formFields/textAreaField.js';
import CheckBoxField from '../formFields/checkBoxField.js';
import InputField from '../formFields/inputField.js';
import DocumentUploadField from '../formFields/documentUploadField.js';
import CustomFieldError from '../formFields/customFieldError.js';
import { useFormikContext } from 'formik';
import { useEffect, useCallback, useState } from 'react';

export default function ReferralReason(props) {
  const { errors, touched, values } = useFormikContext();
  const [showADHD, setShowADHD] = useState(false);

  // 1 ADHD, 3 unused previousl ADHD/ASD. SR change 17.11
  const isADHDReferral = useCallback(async () => {
    if (
      values.referralTypeID &&
      (values.referralTypeID === 1 || values.referralTypeID === 3)
    ) {
      setShowADHD(true);
      values.isValidADHD = true;
    } else {
      // ensure values are reset
      setShowADHD(false);
      values.isValidADHD = false;
    }
  }, [values.referralTypeID]);

  useEffect(() => {
    isADHDReferral();
    window.scrollTo(0, 20); // 20 prevent scrolling issues, not firing on build
  }, [isADHDReferral]);

  const filterFreq = props.category.filter(
    (x) => x.CategoriesGroup === 'SOCIAL',
  );

  const filterYesNoHas = props.category.filter(
    (x) => x.CategoriesGroup === 'YESNO' && x.ValueCode !== 'NotKnown',
  );

  const filterYesNo = props.category.filter(
    (x) =>
      x.CategoriesGroup === 'YESNO' &&
      x.ValueCode !== 'NotKnown' &&
      x.ValueCode !== 'Past',
  );

  const filterCommm = props.category.filter(
    (x) =>
      x.CategoriesGroup === 'COMM' &&
      x.ValueCode !== 'NotUsed' &&
      x.ValueCode !== 'NotFollow' &&
      x.ValueCode !== 'NotExpression',
  );

  const filterCommmSpokenLanguage = props.category.filter(
    (x) =>
      x.CategoriesGroup === 'COMM' &&
      x.ValueCode !== 'NoUnderstanding' &&
      x.ValueCode !== 'NotFollow' &&
      x.ValueCode !== 'NotExpression',
  );

  const filterCommmConversations = props.category.filter(
    (x) =>
      x.CategoriesGroup === 'COMM' &&
      x.ValueCode !== 'NoUnderstanding' &&
      x.ValueCode !== 'NotUsed' &&
      x.ValueCode !== 'NotExpression',
  );

  const filterCommmExpressions = props.category.filter(
    (x) =>
      x.CategoriesGroup === 'COMM' &&
      x.ValueCode !== 'NoUnderstanding' &&
      x.ValueCode !== 'NotUsed' &&
      x.ValueCode !== 'NotFollow',
  );

  const filterPeers = props.category.filter(
    (x) =>
      x.CategoriesGroup === 'ATTENTION' &&
      x.ValueCode !== 'CannotSustainAdult' &&
      x.ValueCode !== 'ActivityLevels',
  );

  const filterPeersAdult = props.category.filter(
    (x) =>
      x.CategoriesGroup === 'ATTENTION' &&
      x.ValueCode !== 'CannotSustain' &&
      x.ValueCode !== 'ActivityLevels',
  );

  const filterPeersActivity = props.category.filter(
    (x) =>
      x.CategoriesGroup === 'ATTENTION' &&
      x.ValueCode !== 'CannotSustainAdult' &&
      x.ValueCode !== 'CannotSustain',
  );

  const filterSensory = props.category.filter(
    (x) => x.CategoriesGroup === 'SENSORY',
  );

  const filterLearning = props.category.filter(
    (x) => x.CategoriesGroup === 'LEARNING',
  );

  const filterSchool = props.category.filter(
    (x) => x.CategoriesGroup === 'SCHLEARNING',
  );

  const filterYesNoNotKnown = props.category.filter(
    (x) => x.CategoriesGroup === 'YESNO' && x.ValueCode !== 'Past',
  );

  const filterBehaviour = props.category.filter(
    (x) => x.CategoriesGroup === 'BEHAVIOUR',
  );

  const filterExDiagnosis = props.category.filter(
    (x) => x.CategoriesGroup === 'EXDIAGNOSIS',
  );

  const filterNoNotKnown = props.category.filter(
    (x) =>
      x.CategoriesGroup === 'YESNO' &&
      x.ValueCode !== 'Past' &&
      x.ValueCode !== 'Yes',
  );

  const exDiagnosis = filterExDiagnosis.concat(filterNoNotKnown);

  return (
    <>
      <Form.Group>
        <h4>Social Interaction</h4>
        <SelectField
          name={'socialInteractionEyeID'}
          label={
            'Compared to other children of their age the child uses eye contact*'
          }
          data={filterFreq}
          disabled={props.processing}
        />
        <SelectField
          name={'socialInteractionGesturesID'}
          label={
            'Compared to other children of their age the child uses gesture to share their intentions*'
          }
          data={filterFreq}
          disabled={props.processing}
        />
        <SelectField
          name={'socialInteractionEmotionsID'}
          label={
            'Compared to other children of their age the child shares their emotions (excitement, pain, fear, happiness)*'
          }
          data={filterFreq}
          disabled={props.processing}
        />
      </Form.Group>
      <Form.Group>
        <h4>Restrictive Pattern of Behaviours</h4>
        <SelectField
          name={'restrictiveBehaviourInterestID'}
          label={
            'The child has a specific area of interest that is more intense or detailed than other children of their age*'
          }
          data={filterYesNoHas}
          disabled={props.processing}
        />
        <SelectField
          name={'restrictiveBehaviourUnusualID'}
          label={
            'The child has a specific area of interest that is unusual compared to  other children of their age*'
          }
          data={filterYesNoHas}
          disabled={props.processing}
        />
        <SelectField
          name={'restrictiveBehaviourRoutineID'}
          label={
            'The child has specific routines they need to keep to throughout the day*'
          }
          data={filterYesNoHas}
          disabled={props.processing}
        />
        <SelectField
          name={'restrictiveBehaviourDistressID'}
          label={
            'The child has routines or rituals that cause them a level of distress if they are not completed*'
          }
          data={filterYesNoHas}
          disabled={props.processing}
        />
      </Form.Group>
      <Form.Group>
        <h4>Communication</h4>
        <SelectField
          name={'communicationUnderstandID'}
          label={'The child understands and responds to spoken language*'}
          data={filterCommm}
          disabled={props.processing}
        />
        <SelectField
          name={'communicationSpokenID'}
          label={'The child uses spoken language*'}
          data={filterCommmSpokenLanguage}
          disabled={props.processing}
        />
        <SelectField
          name={'communicationFollowsID'}
          label={'The child follows and joins in conversations*'}
          data={filterCommmConversations}
          disabled={props.processing}
        />
        <SelectField
          name={'communicationExpressionsID'}
          label={
            'The child understands and uses facial expressions, tone of voice and body language*'
          }
          data={filterCommmExpressions}
          disabled={props.processing}
        />
      </Form.Group>
      <Form.Group>
        <h4>Attention and Listening</h4>
        <SelectField
          name={'attentionSelfID'}
          label={
            'The child can sustain their attention to a self chosen task for*'
          }
          data={filterPeers}
          disabled={props.processing}
        />
        <SelectField
          name={'attentionLedID'}
          label={
            'The child can sustain their attention to an adult led task for*'
          }
          data={filterPeersAdult}
          disabled={props.processing}
        />
        <SelectField
          name={'attentionLevelID'}
          label={
            'The child can adjust their activity levels to the social context*'
          }
          data={filterPeersActivity}
          disabled={props.processing}
        />
      </Form.Group>
      <Form.Group>
        <h4>Sensory Processing</h4>
        <SelectField
          name={'sensoryLoudID'}
          label={'The child responds to loud sounds or noisy environments*'}
          data={filterSensory}
          disabled={props.processing}
        />
        <SelectField
          name={'sensoryTasteID'}
          label={'The child responds to tastes and textures*'}
          data={filterSensory}
          disabled={props.processing}
        />
      </Form.Group>
      <Form.Group>
        <h4>Learning</h4>
        <SelectField
          name={'learningBehindID'}
          label={"The child's learning is*"}
          data={filterLearning}
          disabled={props.processing}
        />
        <SelectField
          name={'learningSettledID'}
          label={'The child has settled into school / nursery*'}
          data={filterSchool}
          disabled={props.processing}
        />
      </Form.Group>
      <Form.Group>
        <h4>Vision</h4>
        <SelectField
          name={'visionPassedID'}
          label={'Has the child had a sight test*'}
          data={filterYesNoNotKnown}
          disabled={props.processing}
        />
        <SelectField
          name={'visionConcernID'}
          label={'Do you have concerns about their sight*'}
          data={filterYesNoNotKnown}
          disabled={props.processing}
        />
      </Form.Group>
      <Form.Group>
        <h4>Hearing</h4>
        <SelectField
          name={'hearingPassedID'}
          label={'Has the child passed a hearing test*'}
          data={filterYesNoNotKnown}
          disabled={props.processing}
        />
        <SelectField
          name={'hearingConcernID'}
          label={'Do you have concerns about their hearing*'}
          data={filterYesNoNotKnown}
          disabled={props.processing}
        />
      </Form.Group>
      <Form.Group>
        <h4>Motor Skills</h4>
        <SelectField
          name={'motorPassedID'}
          label={
            "Do you have concerns about the child's fine motor skills (writing, doing up buttons, zips)*"
          }
          data={filterYesNoNotKnown}
          disabled={props.processing}
        />
        <SelectField
          name={'motorConcernID'}
          label={
            "Do you have concerns about the child's gross motor skills (walking running jumping)*"
          }
          data={filterYesNoNotKnown}
          disabled={props.processing}
        />
      </Form.Group>
      <Form.Group>
        <h4>Behaviour</h4>
        <SelectField
          name={'behaviourID'}
          label={"The child's behaviour is*"}
          data={filterBehaviour}
          disabled={props.processing}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <h4>Stereotypical Mannerisms</h4>
        <Form.Check.Label className="mb-2">
          Does the child show any stereotypical mannerisms / stimming*
        </Form.Check.Label>

        {props.mannerisms.map((item, index) => (
          <CheckBoxField
            key={index}
            name={`mannerisms`}
            value={item.Id}
            label={item.Description}
            id={item.Description}
            type="checkbox"
            disabled={
              props.processing ||
              (item.Id === 6 &&
                values.mannerisms.length > 0 &&
                !values.mannerisms.includes('6')) ||
              (item.Id !== 6 &&
                values.mannerisms.length > 0 &&
                values.mannerisms.includes('6'))
            }
          />
        ))}

        <CustomFieldError name={'mannerisms'} />
      </Form.Group>
      <TextAreaField
        name="mannerismsComment"
        disabled={props.processing || !values.mannerisms.includes('7')} //7 is other
        as="textarea"
        rows={3}
        label="Other mannerisms not listed"
      />
      <h4>Other Medical Needs</h4>
      <SelectField
        name={'motorLostID'}
        label={
          'Has the child ever lost any motor skills? (e.g. walking skills worsened significantly or lost ability to walk after achieving this)*'
        }
        data={filterYesNoNotKnown}
        disabled={props.processing}
      />
      <InputField
        name="motorLostComment"
        disabled={props.processing || values.motorLostID !== 1}
        type="text"
        label="If yes, please give details"
      />
      <SelectField
        name={'learningDelayID'}
        label={'Does the child have moderate to severe learning delay?*'}
        data={filterYesNoNotKnown}
        disabled={props.processing}
      />
      <InputField
        name="learningDelayComment"
        disabled={props.processing || values.learningDelayID !== 1}
        type="text"
        label="If yes, please give details"
      />
      <SelectField
        name={'autFamilyID'}
        label={
          'Is there a family history of Autism/ADHD/learning delay? e.g. sibling / parent / 1st cousin / uncle / aunt*'
        }
        data={filterYesNoNotKnown}
        disabled={props.processing}
      />
      <InputField
        name="autFamilyComment"
        disabled={props.processing || values.autFamilyID !== 1}
        type="text"
        label="If yes, please give details"
      />
      <SelectField
        name={'existingDiagnosisID'}
        label={
          'Does the child/young person already have a diagnosis of ADHD and/or ASD*'
        }
        data={exDiagnosis}
        disabled={props.processing}
      />
      <SelectField
        name={'supportingDocumentsID'}
        label={'Do you have documentation to support diagnosis'}
        data={[
          {
            Id: 1,
            CategoriesGroup: 'YESNO',
            ValueCode: 'Yes',
            Description: 'Yes',
            OrderBy: 1,
            IsActive: true,
          },
          {
            Id: 2,
            CategoriesGroup: 'YESNO',
            ValueCode: 'No',
            Description: 'No',
            OrderBy: 2,
            IsActive: true,
          },
          {
            Id: 3,
            CategoriesGroup: 'YESNO',
            ValueCode: 'NotKnown',
            Description: 'Not Known',
            OrderBy: 3,
            IsActive: true,
          },
        ]}
        disabled={
          props.processing ||
          values.existingDiagnosisID === 2 ||
          values.existingDiagnosisID === 3
        }
      />
      <DocumentUploadField
        name={'documents'}
        label={'Supporting document upload'}
        disabled={
          props.processing ||
          values.existingDiagnosisID === 2 ||
          values.existingDiagnosisID === 3 ||
          values.supportingDocumentsID === 2 ||
          values.supportingDocumentsID === 3
        }
      />
      {showADHD && (
        <>
          <h4>Attention</h4>
          <SelectField
            name={'adhdAttentionSpanID'}
            label={
              'Compared to other children/young people of their age, do they have a short attention span?*'
            }
            data={filterYesNoNotKnown}
            disabled={props.processing}
          />
          <InputField
            name="adhdAttentionSpanComment"
            disabled={props.processing || values.adhdAttentionSpanID !== 1}
            type="text"
            label="If yes, please give details"
          />

          <SelectField
            name={'adhdDistractedID'}
            label={
              'Compared to other children/young people of their age are they easily distracted?*'
            }
            data={filterYesNoNotKnown}
            disabled={props.processing}
          />
          <InputField
            name="adhdDistractedComment"
            disabled={props.processing || values.adhdDistractedID !== 1}
            type="text"
            label="If yes, please give details"
          />

          <SelectField
            name={'adhdOrganisationID'}
            label={
              'Compared to other children/young people of their age do they have poor organisation?*'
            }
            data={filterYesNoNotKnown}
            disabled={props.processing}
          />
          <InputField
            name="adhdOrganisationComment"
            disabled={props.processing || values.adhdOrganisationID !== 1}
            type="text"
            label="If yes, please give details"
          />

          <SelectField
            name={'adhdTasksID'}
            label={
              'Compared to other children/young people of their age do they struggle to complete given tasks?*'
            }
            data={filterYesNoNotKnown}
            disabled={props.processing}
          />
          <InputField
            name="adhdTasksComment"
            disabled={props.processing || values.adhdTasksID !== 1}
            type="text"
            label="If yes, please give details"
          />
          <h4>Activity</h4>

          <SelectField
            name={'adhdFidgetingID'}
            label={
              'Compared to other children/young people of their age, does this child display more fidgeting?*'
            }
            data={filterYesNoNotKnown}
            disabled={props.processing}
          />
          <InputField
            name="adhdFidgetingComment"
            disabled={props.processing || values.adhdFidgetingID !== 1}
            type="text"
            label="If yes, please give details"
          />

          <SelectField
            name={'adhdChattingID'}
            label={
              'Compared to other children/young people of their age, does this child display more chatting?*'
            }
            data={filterYesNoNotKnown}
            disabled={props.processing}
          />
          <InputField
            name="adhdChattingComment"
            disabled={props.processing || values.adhdChattingID !== 1}
            type="text"
            label="If yes, please give details"
          />

          <SelectField
            name={'adhdSquirmingID'}
            label={
              'Compared to other children/young people of their age, does this child display more squirming?*'
            }
            data={filterYesNoNotKnown}
            disabled={props.processing}
          />
          <InputField
            name="adhdSquirmingComment"
            disabled={props.processing || values.adhdSquirmingID !== 1}
            type="text"
            label="If yes, please give details"
          />

          <SelectField
            name={'adhdStillID'}
            label={
              'Compared to other children/young people of their age, does this child display not keeping still? (Constantly on the go)*'
            }
            data={filterYesNoNotKnown}
            disabled={props.processing}
          />
          <InputField
            name="adhdStillComment"
            disabled={props.processing || values.adhdStillID !== 1}
            type="text"
            label="If yes, please give details"
          />

          <h4>Impulses</h4>

          <SelectField
            name={'adhdThinkingID'}
            label={
              'Compared to other children/young people of their age, does this child display more acting without thinking?*'
            }
            data={filterYesNoNotKnown}
            disabled={props.processing}
          />
          <InputField
            name="adhdThinkingComment"
            disabled={props.processing || values.adhdThinkingID !== 1}
            type="text"
            label="If yes, please give details"
          />

          <SelectField
            name={'adhdConversationsID'}
            label={
              'Compared to other children/young people of their age, does this child display more interrupting conversations?*'
            }
            data={filterYesNoNotKnown}
            disabled={props.processing}
          />
          <InputField
            name="adhdConversationsComment"
            disabled={props.processing || values.adhdConversationsID !== 1}
            type="text"
            label="If yes, please give details"
          />

          <SelectField
            name={'adhdTurnID'}
            label={
              'Compared to other children/young people of their age, does this child display more difficulties waiting for their turn?*'
            }
            data={filterYesNoNotKnown}
            disabled={props.processing}
          />
          <InputField
            name="adhdTurnComment"
            disabled={props.processing || values.adhdTurnID !== 1}
            type="text"
            label="If yes, please give details"
          />

          <SelectField
            name={'adhdRiskyID'}
            label={
              'Compared to other children/young people of their age, does this child display more risky behaviour without consideration of consequence?*'
            }
            data={filterYesNoNotKnown}
            disabled={props.processing}
          />
          <InputField
            name="adhdRiskyComment"
            disabled={props.processing || values.adhdRiskyID !== 1}
            type="text"
            label="If yes, please give details"
          />
        </>
      )}
      <hr className="form-divider" />
      <SelectField
        name={'additionalMedicalID'}
        label={'Any additional medical concerns or conditions?*'}
        data={filterYesNo}
        disabled={props.processing}
      />
      <InputField
        name="additionalMedicalComment"
        disabled={props.processing || values.additionalMedicalID !== 1}
        type="text"
        label="If yes, please give details"
      />
      <hr className="form-divider" />
      <TextAreaField
        name="additionalInformation"
        disabled={props.processing}
        as="textarea"
        rows={5}
        label="Additional Information"
      />
      {Object.keys(touched).length > 0 && Object.keys(errors).length > 0 && (
        <p className="customError mt-4">
          Please complete all required fields (*)
        </p>
      )}
    </>
  );
}
