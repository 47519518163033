import * as yup from 'yup';

import { intRequired, arrayContains, stringRequired } from '../fields/index.js';

export default yup.object().shape({
  childTheirSafetyID: intRequired('This information is required'),
  childTheirSafetyComment: yup.string().test({
    test: function (value) {
      if (this.parent.childTheirSafetyID === 1 && !value) {
        return this.createError({
          path: 'childTheirSafetyComment',
          message: 'Selected YES. This information is required',
        });
      } else if (this.parent.childTheirSafetyID === 1 && value) {
        let validLength = yup.string().max(500).isValidSync(value);
        if (validLength) {
          return true;
        } else {
          return this.createError({
            path: 'childTheirSafetyComment',
            message:
              'Selected YES. Text length cannot be greater than 500 characters.',
          });
        }
      } else {
        return true;
      }
    },
  }),
  childSafetyOfOthersID: intRequired('This information is required'),
  childSafetyOfOthersComment: yup.string().test({
    test: function (value) {
      if (this.parent.childSafetyOfOthersID === 1 && !value) {
        return this.createError({
          path: 'childSafetyOfOthersComment',
          message: 'Selected YES. This information is required',
        });
      } else if (this.parent.childSafetyOfOthersID === 1 && value) {
        let validLength = yup.string().max(500).isValidSync(value);
        if (validLength) {
          return true;
        } else {
          return this.createError({
            path: 'childSafetyOfOthersComment',
            message:
              'Selected YES. Text length cannot be greater than 500 characters.',
          });
        }
      } else {
        return true;
      }
    },
  }),
  childExclusionID: intRequired('This information is required'),
  childAdoptionID: intRequired('This information is required'),
  childProtectionPlanID: intRequired('This information is required'),
  childInNeedPlanID: intRequired('This information is required'),
  childReferredMashID: intRequired('This information is required'),
  educationPlansInPlace: arrayContains('This information is required'),
  // otherDiagnosis: arrayContains('Other health conditions'),
  // otherDiagnosisComment: yup.string().when('otherDiagnosis', {
  //   is: (otherDiagnosis) =>
  //     // 23 is other
  //     otherDiagnosis && otherDiagnosis.includes('23'),
  //   then: stringRequired('Selected other - other health condition detail'),
  // }),
  referralReason: stringRequired('This information is required'),
});
