export function pdfReferenceValues(
  pdfFormValues,
  referrerTitle,
  ndtContactRef,
  genLanguages,
  school,
  gPPractice,
  eppRef,
  gPRegistration,
  category,
  mannerismsRef,
  referralTypeRef,
  ethnicityRef,
) {
  let pdf = pdfFormValues;

  //Filter selects for pdf descriptions

  pdf['ethnicity'] = ethnicityRef.find(
    (x) => x.Id === pdfFormValues.ethnicityID,
  ).Description;

  pdf['residing'] = category.find(
    (x) => x.Id === pdfFormValues.childResidingID,
  ).Description;

  pdf['parental'] = category.find(
    (x) => x.Id === pdfFormValues.parentalResponibilityID,
  ).Description;

  pdf['title'] = referrerTitle.find(
    (x) => x.Id === pdfFormValues.referrerTitleID,
  ).Description;

  if (pdfFormValues.interpreterGenLanguageCode) {
    pdf['interpreterLanguage'] = genLanguages.find(
      (x) => x.Code === pdfFormValues.interpreterGenLanguageCode,
    ).CodeDescription;
  }

  if (pdfFormValues.gPPracticeID) {
    pdf['gp'] = gPPractice.find(
      (x) => x.Id === pdfFormValues.gPPracticeID,
    ).GPPracticeName_Current;
  }
  pdf['gPRegistration'] = gPRegistration.find(
    (x) => x.Id === pdfFormValues.gPRegistrationID,
  ).Description;

  if (pdfFormValues.schoolID) {
    pdf['schoolName'] = school.find(
      (x) => x.Id === pdfFormValues.schoolID,
    ).SchoolName;
  }
  //Checkboxes are saved as strings, need to convert the ref table ids to strings
  pdf['ndtContact'] = ndtContactRef
    .filter((x) => pdf.referrerNdtContact.includes(x.Id.toString()))
    .map((x) => x.Description);

  pdf['childTheirSafety'] = category.find(
    (x) => x.Id === pdfFormValues.childTheirSafetyID,
  ).Description;

  pdf['childSafetyOfOthers'] = category.find(
    (x) => x.Id === pdfFormValues.childSafetyOfOthersID,
  ).Description;

  pdf['childExclusion'] = category.find(
    (x) => x.Id === pdfFormValues.childExclusionID,
  ).Description;

  pdf['childAdoption'] = category.find(
    (x) => x.Id === pdfFormValues.childAdoptionID,
  ).Description;

  pdf['childProtectionPlan'] = category.find(
    (x) => x.Id === pdfFormValues.childProtectionPlanID,
  ).Description;

  pdf['childInNeedPlan'] = category.find(
    (x) => x.Id === pdfFormValues.childInNeedPlanID,
  ).Description;

  pdf['childReferredMash'] = category.find(
    (x) => x.Id === pdfFormValues.childReferredMashID,
  ).Description;

  pdf['childProtectionPlan'] = category.find(
    (x) => x.Id === pdfFormValues.childProtectionPlanID,
  ).Description;

  pdf['childInNeedPlan'] = category.find(
    (x) => x.Id === pdfFormValues.childInNeedPlanID,
  ).Description;

  pdf['eppPDF'] = eppRef
    .filter((x) => pdf.educationPlansInPlace.includes(x.Id.toString()))
    .map((x) => x.Description);

  pdf['socialInteractionEye'] = category.find(
    (x) => x.Id === pdfFormValues.socialInteractionEyeID,
  ).Description;

  pdf['socialInteractionGestures'] = category.find(
    (x) => x.Id === pdfFormValues.socialInteractionGesturesID,
  ).Description;

  pdf['socialInteractionEmotions'] = category.find(
    (x) => x.Id === pdfFormValues.socialInteractionEmotionsID,
  ).Description;

  pdf['restrictiveBehaviourInterest'] = category.find(
    (x) => x.Id === pdfFormValues.restrictiveBehaviourInterestID,
  ).Description;

  pdf['restrictiveBehaviourUnusual'] = category.find(
    (x) => x.Id === pdfFormValues.restrictiveBehaviourUnusualID,
  ).Description;

  pdf['restrictiveBehaviourRoutine'] = category.find(
    (x) => x.Id === pdfFormValues.restrictiveBehaviourRoutineID,
  ).Description;

  pdf['restrictiveBehaviourDistress'] = category.find(
    (x) => x.Id === pdfFormValues.restrictiveBehaviourDistressID,
  ).Description;

  pdf['communicationUnderstand'] = category.find(
    (x) => x.Id === pdfFormValues.communicationUnderstandID,
  ).Description;

  pdf['communicationSpoken'] = category.find(
    (x) => x.Id === pdfFormValues.communicationSpokenID,
  ).Description;

  pdf['communicationFollows'] = category.find(
    (x) => x.Id === pdfFormValues.communicationFollowsID,
  ).Description;

  pdf['communicationExpression'] = category.find(
    (x) => x.Id === pdfFormValues.communicationExpressionsID,
  ).Description;

  pdf['attentionSelf'] = category.find(
    (x) => x.Id === pdfFormValues.attentionSelfID,
  ).Description;

  pdf['attentionLed'] = category.find(
    (x) => x.Id === pdfFormValues.attentionLedID,
  ).Description;

  pdf['attentionLevel'] = category.find(
    (x) => x.Id === pdfFormValues.attentionLevelID,
  ).Description;

  pdf['sensoryLoud'] = category.find(
    (x) => x.Id === pdfFormValues.sensoryLoudID,
  ).Description;

  pdf['sensoryTaste'] = category.find(
    (x) => x.Id === pdfFormValues.sensoryTasteID,
  ).Description;

  pdf['learningBehind'] = category.find(
    (x) => x.Id === pdfFormValues.learningBehindID,
  ).Description;

  pdf['learningSettled'] = category.find(
    (x) => x.Id === pdfFormValues.learningSettledID,
  ).Description;

  pdf['visionPassed'] = category.find(
    (x) => x.Id === pdfFormValues.visionPassedID,
  ).Description;

  pdf['visionConcern'] = category.find(
    (x) => x.Id === pdfFormValues.visionConcernID,
  ).Description;

  pdf['hearingPassed'] = category.find(
    (x) => x.Id === pdfFormValues.hearingPassedID,
  ).Description;

  pdf['hearingConcern'] = category.find(
    (x) => x.Id === pdfFormValues.hearingConcernID,
  ).Description;

  pdf['motorPassed'] = category.find(
    (x) => x.Id === pdfFormValues.motorPassedID,
  ).Description;

  pdf['motorConcern'] = category.find(
    (x) => x.Id === pdfFormValues.motorConcernID,
  ).Description;

  pdf['behaviour'] = category.find(
    (x) => x.Id === pdfFormValues.behaviourID,
  ).Description;

  pdf['otherManPDF'] = mannerismsRef
    .filter((x) => pdf.mannerisms.includes(x.Id.toString()))
    .map((x) => x.Description);

  pdf['motorLost'] = category.find(
    (x) => x.Id === pdfFormValues.motorLostID,
  ).Description;

  pdf['learningDelay'] = category.find(
    (x) => x.Id === pdfFormValues.learningDelayID,
  ).Description;

  pdf['autFamily'] = category.find(
    (x) => x.Id === pdfFormValues.autFamilyID,
  ).Description;

  pdf['existingDiagnosis'] = category.find(
    (x) => x.Id === pdfFormValues.existingDiagnosisID,
  ).Description;

  pdf['supportingDocuments'] = category.find(
    (x) => x.Id === pdfFormValues.supportingDocumentsID,
  ).Description;

  //ADHD
  if (pdfFormValues.isValidADHD) {
    pdf['adhdAttentionSpan'] = category.find(
      (x) => x.Id === pdfFormValues.adhdAttentionSpanID,
    ).Description;

    pdf['adhdDistracted'] = category.find(
      (x) => x.Id === pdfFormValues.adhdDistractedID,
    ).Description;

    pdf['adhdOrganisation'] = category.find(
      (x) => x.Id === pdfFormValues.adhdOrganisationID,
    ).Description;

    pdf['adhdTasks'] = category.find(
      (x) => x.Id === pdfFormValues.adhdTasksID,
    ).Description;

    pdf['adhdFidgeting'] = category.find(
      (x) => x.Id === pdfFormValues.adhdFidgetingID,
    ).Description;

    pdf['adhdChatting'] = category.find(
      (x) => x.Id === pdfFormValues.adhdChattingID,
    ).Description;

    pdf['adhdSquirming'] = category.find(
      (x) => x.Id === pdfFormValues.adhdSquirmingID,
    ).Description;

    pdf['adhdStill'] = category.find(
      (x) => x.Id === pdfFormValues.adhdStillID,
    ).Description;

    pdf['adhdThinking'] = category.find(
      (x) => x.Id === pdfFormValues.adhdThinkingID,
    ).Description;

    pdf['adhdConversations'] = category.find(
      (x) => x.Id === pdfFormValues.adhdConversationsID,
    ).Description;

    pdf['adhdTurn'] = category.find(
      (x) => x.Id === pdfFormValues.adhdTurnID,
    ).Description;

    pdf['adhdRisky'] = category.find(
      (x) => x.Id === pdfFormValues.adhdRiskyID,
    ).Description;
  }

  pdf['referralType'] = referralTypeRef.find(
    (x) => x.Id === pdfFormValues.referralTypeID,
  ).Description;

  pdf['additionalMedical'] = category.find(
    (x) => x.Id === pdfFormValues.additionalMedicalID,
  ).Description;

  return pdfFormValues;
}
